import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import store from '../store/index'

const msalInstance = new PublicClientApplication(
    store.state.msalConfig,
);

axios.interceptors.request.use(
  async (config) => { 
    let request = {
      scopes: [process.env.VUE_APP_SCOPE_API],
      account: store.state.account
  };
    let tokenResponse = ''
    
    try {
      tokenResponse = await msalInstance.acquireTokenSilent(request);
     
      store.commit('setAccessToken', tokenResponse.accessToken)
      
    } catch (error) {
      console.error( 'Silent token acquisition failed. Using interactive mode' ); 
    }
    config.headers['Authorization'] = 'Bearer ' + tokenResponse.accessToken
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios

