import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SalesReport from '../views/Crm/SalesReport.vue'
import SalesReport2 from '../views/Crm/SalesReport2.vue'
import PhoneReport from '../views/Phone/Report.vue'
import PhoneUsers from '../views/Phone/Users.vue'
import PhoneEquipes from '../views/Phone/Equipes'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/board/sales',
    name: 'SalesReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SalesReport.vue')
    component: SalesReport
  },
  {
    path: '/board/sales2',
    name: 'SalesReport2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SalesReport.vue')
    component: SalesReport2
  },
  {
    path: '/board/phone',
    name: 'PhoneReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SalesReport.vue')
    component: PhoneReport
  },
  {
    path: '/phone/users',
    name: 'PhoneUsers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SalesReport.vue')
    component: PhoneUsers
  },
  {
    path: '/phone/equipes',
    name: 'PhoneEquipes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SalesReport.vue')
    component: PhoneEquipes
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
