<template>
    <nav class="navbar navbar-expand-lg navbar-light " style="background-color: #7e97c5;">
        <div class="container-fluid">
            <a class="navbar-brand" href="#"><img src="/logomanageo.png" width="120" height="40"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
                    </li>
                    <li class="nav-item">
                    <router-link to="/board/sales" class="nav-link">Sales Board PE</router-link>
                    </li>
                    <li class="nav-item">
                    <router-link to="/board/sales2" class="nav-link">Sales Board PE 2</router-link>
                    </li>
                    <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Phones - Rainbow 
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link to="/board/phone" class="dropdown-item">Sales Board PE</router-link></li>
                        <li><router-link to="/phone/users" class="dropdown-item">Utilisateurs</router-link></li>
                        <li><router-link to="/phone/equipes" class="dropdown-item">Equipes</router-link></li>
                    </ul>
                    </li>
                </ul>
                <span class="navbar-text">
                    <a href="#">
                    <span v-if="!this.$store.state.account" @click="SignIn"><small>login</small></span>
                        <span v-if="this.$store.state.account" @click="SignOut"><small>{{ account.username + ' (' + account.name + ')'}}</small></span>
                    </a>
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
  name: 'HeaderBar',
  data() {
    return {
      account: undefined
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
        this.$store.commit('setAccount',undefined)
        return;
    }
    this.account = accounts[0];
    this.$store.commit('setAccount',this.account)
   // console.log('ACCOUNT', this.$store.state)
    //this.getAccessToken()
   
    //this.$emitter.emit('login', this.account);
  },
  methods: {
      
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          this.$store.commit('setAccount',myAccounts[0])
          //this.getAccessToken()
          //this.$emitter.emit('login', this.account);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
            this.$store.commit('setAccount',undefined)
            this.$store.commit('setAccessToken', '');
            this.$emitter.emit('logout', 'logging out');
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
}
</script>