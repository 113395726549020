import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Emitter from 'tiny-emitter';
import axios from './modules/apiModel'

const app = createApp(App)
app.config.globalProperties.axios = axios
app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()
app.use(store)
app.use(router).mount('#app')
