<template>
    <div class="container" style="margin-top:50px" v-if="this.$store.state.account">  
        <h1>Liste des utilisateurs</h1>
        <p><a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewUser(null, 2)"><span class='glyphicon glyphicon-edit'></span> Créer un nouvel utilisateur</a></p>
        <table class="table table-striped" style="font-size:10px">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Poste</th>
                    <th>Equipe</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for= "(item, index) in users" v-bind:key="item.Poste">
                    <td>{{ index +1 }}</td>
                    <td>{{ item.Nom }}</td>
                    <td>{{ item.Prenom }}</td>
                    <td>{{ item.Poste }}</td>
                    <td>{{ item.equipes.Nom }}</td>
                    <td>
                        <a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewUser(item.Poste, 3)"><span class='glyphicon glyphicon-edit'></span> Edit</a> |
                        <a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewUser(item.Poste, 1)"><span class='glyphicon glyphicon-barcode'></span> Details</a> |
                        <a  href="#" @click.stop.prevent="deleteUser(item.Poste)"><span class='glyphicon glyphicon-ban-circle'></span> Delete |</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- Modal pour le CRUD -->
        <div id="modal-user" class="modal" tabindex="-1">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">{{titleModal}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="alert alert-primary" role="alert" v-if="alert.show">
                        {{alert.message}}
                        </div>
                    <div class="mb-3" >
                        <label for="poste" class="form-label">Numéro de poste</label>
                        <input type="number" class="form-control" id="poste" placeholder="ex: 3356" :disabled="isViewMode"  v-model.number="user.Poste">
                    </div>
                    <div class="mb-3" >
                        <label for="nom" class="form-label">Nom</label>
                        <input type="text" class="form-control" id="nom" placeholder="Nom de l'utilisateur" :disabled="isViewMode" v-model="user.Nom">
                    </div>
                    <div class="mb-3" >
                        <label for="prenom" class="form-label">Prénom</label>
                        <input type="text" class="form-control" id="prenom" placeholder="Prenom de l'utilisateur" :disabled="isViewMode" v-model="user.Prenom">
                    </div>
                    <div class="mb-3" >
                        <label for="equipe" class="form-label">Equipe</label>
                        <select class="form-select" aria-label="Default select example" id="equipe" :disabled="isViewMode" v-model="user.Equipe">
                            <option id="lstEquipe" v-for="Item in servicesList" v-bind:key="Item.Id" v-bind:value="Item.Id" :selected="user.Equipe === Item.Id">{{ Item.Nom }}</option>
                        </select>
                    </div>
                </form>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                <button type="button" class="btn btn-primary" v-if="!isViewMode" @click="saveUser()">{{mode === 2? 'Create new user' : 'Save changes'}}</button>
                </div>
            </div>
            </div>
        </div>
    </div>

    <div class="row" v-if="!this.$store.state.account" style="margin-top:50px">
        <h2>Vous n'avez pas les droits pour accéder à ce rapport</h2>
    </div>
</template>

<script>
   
    //import axios2 from 'axios'
import axios from 'axios'
    export default {
        name: 'Users',
        components :{
        }, 
        data() {
            return{
                users: [],
                user: {},
                oldPoste : 0,
                servicesList: [],
                mode: 0, //1: view, 2 create, 3 edit
                alert : {show:false, message:''}
            }
        },
        mounted (){
            //console.log('AXIOS NORMAL',axios2)
            //console.log('AXIOS IMPORTE',axios, axios.get)
            alert('iiii')
            this.loadListUsers()
            
            axios.get(process.env.VUE_APP_ROOT_API + '/phone/equipes')
            .then(response => {
                if(response.data){
                    this.servicesList = response.data
                }
            })
        },
        methods: {
            loadListUsers: function() {
                alert(process.env.VUE_APP_ROOT_API + '/phone/users')
                axios.get(process.env.VUE_APP_ROOT_API + '/phone/users')
                .then(response => {
                    if(response.data){
                        this.users = response.data
                    }
                })  
            },
            deleteUser :  function (poste){
            
                if(confirm("Voulez-vous réellement supprimer cet enregistrement")){
                    axios.delete(process.env.VUE_APP_ROOT_API + '/phone/users/' + poste)
                    .then(() => {
                    
                        alert("Suppression réalisée avec succès")
                        this.loadListUsers()
                    }).catch((err) => {
                        alert('erreur dans la suppresion : ' + err)
                    })
                }
            },
            saveUser: function() {
                this.alert.show = false
                this.alert.message = ''

                if(this.mode !== 2 && this.mode !== 3) {
                    this.alert.show = true
                    this.alert.message = "Erreur inconnue, impossible d'enregitrer l'utilisateur "
                    return
                }
                console.log(!this.user.Poste)
                if(!this.user.Poste) {
                    this.alert.show = true
                    this.alert.message = "Le numéro de poste ne peut pas être vide "
                    return
                }
                if(!this.user.Nom || this.user.Nom.trim() === "") {
                    this.alert.show = true
                    this.alert.message = "Le nom est obligatoire "
                    return
                }
                if(!this.user.Equipe || this.user.Nom.trim() === 0) {
                    this.alert.show = true
                    this.alert.message = "Vous devez choisir une équipe "
                    return
                }

                if(this.mode === 2){
                    axios.post(process.env.VUE_APP_ROOT_API + '/phone/users/', this.user)
                    .then((response) => {
                        
                        this.user=response.data
                        this.mode = 1
                        this.loadListUsers()
                        
                    }).catch((err) => {
                        this.alert.message = err
                        this.alert.show = true
                    })
                }else if(this.mode === 3){
                    axios.put(process.env.VUE_APP_ROOT_API + '/phone/users/' + this.oldPoste, this.user)
                    .then((response) => {
                        this.user=response.data
                        this.mode = 1
                        this.loadListUsers()
                        
                    }).catch((err) => {
                        this.alert.message = err
                        this.alert.show = true
                    })
                }
            },
            viewUser: function(poste, mode) {
                this.oldPoste = poste
                this.alert.show = false
                this.alert.message = ''
                this.user = {}
                this.mode = 0
                if(poste) {
                    
                    axios.get(process.env.VUE_APP_ROOT_API + '/phone/users/' +poste)
                    .then(response => {
                        if(response.data){
                        
                            this.mode = mode //3 edit, 2 view
                            console.log(this.mode)
                            this.user = response.data
                        }
                    })
                
                }else{
                    this.mode = mode
                }
            }
        },
        computed: {
            isViewMode: function () {
                return this.mode === 1 || this.mode === 0 ? true: false
            },
            titleModal :  {
                get: function () {
                    let title = ""
                    if(this.mode === 1){
                        title= "Visualisation de l'utilisatuer " + this.user.Poste + 
                        " (" + this.user.Nom + ")"
                    }
                    if(this.mode === 2){
                        title = "Création d'un nouvel utilisateur"
                    }
                    if(this.mode === 3){
                        title= "Modification de l'utilisatuer " + this.user.Poste + 
                        " (" + this.user.Nom + ")"
                    }
                    return title           
                }
            }
        }
    }

</script>