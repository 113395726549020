<template>
<div class="container" style="margin-top:50px">
    <div class="card">
      <div class="card-header">
        Intranet manageo
      </div>
      <div class="card-body">
        <h5 class="card-title">KPI ans sales metrics</h5>
        <p class="card-text">Statistiques des ventes Pole PE, KPI téléphoniques, ... </p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
    
  </div>
</template>

<script>


export default {
  name: 'HeaderBar',
  components :{
    
  }, 
  data() {
    return {
      account: undefined
    }
  },
  mounted (){
    
  }
}

</script>