<template>



<div class="container-fluid" style="margin-top:30px" v-if="this.$store.state.account">
  <div class="container-fluid" >
          <form class="row g-3">
            
            <div class="col-auto">
              <select class="form-select" aria-label="Default select example" v-model="equipe" @change="loadreport">
                <label for="lstEquipe" class="col-sm-2 col-form-label">>Equipe :</label>
                <option id="lstEquipe" v-for="Item in servicesList" v-bind:key="Item.Id" v-bind:value="Item.Id">{{ Item.Nom }}</option>
              </select>
            </div>
            <div class="col-sm-6">
              <label for="staticEmail2" class="visually-hidden">Range date :</label>
              <Datepicker :enableTimePicker="false" v-model="date" range @update:modelValue="loadreport()"/>
            </div>
            
          </form>
        </div>
 
  <div>
     <table class="table table-striped jambo_table " style="font-size:8px">
                            <thead>

                                <tr>
                                    <th></th>
                                    <th class="headings bor" colspan="3">Entrants </th>

                                    <th class="headings" colspan="3">Sortant </th>

                                    <th class="headings" colspan="3">Total </th>

                                    <th class="headings" colspan="2">Moyenne quotidienne</th>

                                </tr>

                                <tr class="headings">
                                    <th>User</th>
                                    <th>Entrants Nb</th>
                                    <th>Entrants Duree</th>
                                    <th>Entrants Durée Moyenne</th>
                                    <th>Sortant Nb</th>
                                    <th>Sortant Duree</th>
                                    <th>Sortant Durée Moyenne</th>
                                    <th>Total Nb</th>
                                    <th>Total Duree</th>
                                    <th>Total Durée Moyenne</th>
                                    <th>Nb Jour</th>
                                    <th>Duree Appel par jour</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in statsAppel" :key="item.poste">
                              <td data-bs-toggle="modal" data-bs-target="#staticBackdrop" ><a href="#" @click.stop.prevent="loadTickets(item.Poste)">{{item.Nom + " " + item.Prenom}}</a></td>
                              <td >{{item.entrants.nombre}}</td>
                              <td >{{item.entrants.duree}}</td>
                              <td >{{item.entrants.dureeMoyenne}}</td>
                              <td >{{item.sortants.nombre}}</td>
                              <td >{{item.sortants.duree}}</td>
                              <td >{{item.sortants.dureeMoyenne}}</td>
                              <td >{{item.total.nombre}}</td>
                              <td >{{item.total.duree}}</td>
                              <td >{{item.total.dureeMoyenne}}</td>
                              <td >{{item.moyenne.nbJour}}</td>
                              <td >{{item.moyenne.duree}}</td>
                              </tr>
                            </tbody>
     </table>
    </div>



    <!-- Modal -->
<div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{'Liste des appels du poste ' + poste + ' dans la période ' + startDateString + ' - ' + endDateString}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table class="table table-striped" style="font-size:8px">
                        <thead>
                        <tr>
                            <th></th>
                            <th>DateAppel</th>
                            <th>NumInital</th>
                            <th>NumPoste</th>
                            <th>Nom</th>
                            <th>TempsReponse</th>
                            <th>Duree</th>
                            <th>NumAppele</th>
                            <th>TypeAppel</th>
                            <th>PosteInitiateurAppel</th>
                            <th>PosteAyantTransfere</th>
                            <th>Transfert</th>
                            <th>AppelNonRepondu</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in appels" :key="item.Identifiant">
                              <td>{{index  +1}}</td>
                              <td >{{item.DateAppel}}</td>
                              <td>{{item.NumInital}}</td>
                              <td>{{item.NumPoste}}</td>
                              <td>{{item.Nom}}</td>
                              <td>{{item.TempsReponse}}</td>
                              <td>{{item.Duree}}</td>
                              <td>{{item.NumAppele}}</td>
                              <td>{{item.TypeAppel}}</td>
                              <td>{{item.PosteInitiateurAppel}}</td>
                              <td>{{item.PosteAyantTransfere}}</td>
                              <td>{{item.Transfert}}</td>
                              <td>{{item.AppelNonRepondu}}</td>
                        </tr>
                      </tbody>
                </table>
        </div>
    </div>
  </div>
</div>


</div>
<div class="row" v-if="!this.$store.state.account" style="margin-top:50px">
      <h2>Vous n'avez pas les droits pour accéder à ce rapport</h2>
  </div>

</template>

<script>


import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import { ref, onMounted } from 'vue';

import axios from 'axios'
export default {
  name: 'ReportPhone',
 components: { Datepicker },
    setup() {
        const date = ref();

        // For demo purposes assign range from the current date
        onMounted(() => {
            const startDate = new Date();
            const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
            date.value = [startDate, endDate];
        })
        
        return {
            date,
           
            
        }
    },
   data() {
    return {
      servicesList: [],
       equipe: 0,
       canLoad: true,
       //response: {},
       statsAppel: [],
       appels: [],
       poste: '',
       startDate: null,
       endDate: null,
       startDateString : '',
       endDateString :''
    }
    },
  props: {
    msg: String
  }, 
  
  methods: {
    loadTickets: function (poste) {
      //alert(poste)
      if(!this.date[0] || !this.date[1]) {
        this.canLoad= false
        
      }else{
       this.poste = poste
        this.startDate = new Date(this.date[0].setHours(1))
        this.endDate = new Date(this.date[1].setHours(23))
        this.startDateString = this.startDate.toISOString().split('T')[0]
        this.endDateString = this.endDate.toISOString().split('T')[0]
        const requestData = {
          dateDeb : this.startDate,
          dateFin : this.endDate,
          poste: this.poste
        }
        axios.post(process.env.VUE_APP_ROOT_API + '/phone/listappels', requestData)
        .then(response => {
 
          if(response.data){
            
 
              this.appels = response.data
            
          }
        })
      }
    },
    loadreport: function () {
      this.canLoad= true
      if(this.equipe === 0 || !this.date[0] || !this.date[1]) {
        this.canLoad= false
        
      }else{
       
        const startDate = new Date(this.date[0].setHours(1))
        const endDate = new Date(this.date[1].setHours(23))
        const requestData = {
          dateDeb : startDate,
          dateFin : endDate,
          equipe: this.equipe
        }
        axios.post(process.env.VUE_APP_ROOT_API +'/report/statsappels', requestData)
        .then(response => {
          if(response.data){
            this.response = response.data
            if(response.data.report){
   
              this.statsAppel = response.data.report.board.detail
            }
          }
        })
      }
    }
  },
  mounted () {
    axios.get(process.env.VUE_APP_ROOT_API + '/phone/equipes?onlystats=true')
      .then(response => {
          if(response.data){
            this.servicesList = response.data
          }
        })
      
  
  }
  
}
</script>