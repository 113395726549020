<template>
  <div id="nav">
    <Navbar/>
  </div>
  <router-view/>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/NavBar.vue'


export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>