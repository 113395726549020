<template>
<div class="container-fluid" style="margin-top:30px">

  <div class="row" v-if="this.$store.state.account">
    <div class="row" >
    <div class="col-4" >
        <ol class="list-group list-group-numbered"  >
            <li class="list-group-item d-flex justify-content-between align-items-start" v-for="item in last5sales" v-bind:key="item.user">
                <div class="ms-2 me-auto">
                <div class="fw-bold">{{ item.user }}</div>
                <p>
                    <b>Client : </b>{{ item.client }}<br>
                    <small>  {{ item.createdon }}</small>
                </p>
                </div>
            <span class="badge bg-primary rounded-pill">{{ item.totalCurrency }}</span>
            </li>
        </ol>
    </div> 

    <div class="col-8" >
       <table class="table table-striped">
            <thead class="table-dark">
                <tr>
                    <td>Chargé d'affaire</td>
                    <td>Aujourd'hui</td>
                    <td>Semaine</td>
                    <td>Mois</td>
                    <td>Mois dernier</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in detailLine" v-bind:key="item.id">
                    <td>{{ item.lastname + ' ' + item.firstname }}</td>
                    <td>{{ item.dayCommandCurrency }}</td>
                    <td>{{ item.weekCommandCurrency  }}</td>
                    <td>{{ item.monthCommandCurrency  }}</td>
                    <td style="color:#aaaaaa">{{ item.lastMonthCommandCurrency  }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="table-primary" >
                    <td>{{ total.name }}</td>
                    <td>{{ total.dayCommandCurrency }}</td>
                    <td>{{ total.weekCommandCurrency  }}</td>
                    <td>{{ total.monthCommandCurrency  }}</td>
                    <td  style="color:#aaaaaa">{{ total.lastMonthCommandCurrency  }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
<div class="row text-center">
        <p><small> {{reportName + ' : ' + reportMonth}}</small></p>
        <p><small><i>{{reportDescription }}</i></small></p>
</div>
</div>

  <div class="row" v-if="!this.$store.state.account">
      <h2>Vous n'avez pas les droits pour accéder à ce rapport</h2>
  </div>
</div>
</template>

<script>

const axios = require('axios')
export default {
  name: 'Report',
  
  props: {
    msg: String
  }, 
  data () {
    return {
    reportName:'',
    reportMonth: '',
    reportDescription: '',
    detailLine: [],
    total: {},
    last5sales: [],
    info: null,
    interval: null
    }
  },
  mounted () {
    this.loadData()
    this.interval = setInterval(() => { this.loadData()},30000)
  },
  methods: {
      loadData: function () {
        
        axios.get(process.env.VUE_APP_ROOT_API + '/report/sales')
      .then(response => {
          if(response.data){
            this.reportName= response.data.reportSalesOfMonth.Name
            this.reportMonth= response.data.reportSalesOfMonth.reportMonth
            this.reportDescription = response.data.reportSalesOfMonth.description
            this.detailLine = response.data.reportSalesOfMonth.board.detail
            this.total =  response.data.reportSalesOfMonth.board.total
            this.last5sales = response.data.reportSalesOfMonth.last5Sales
          }
        })
      }
  },
  beforeUnmount: function(){
        clearInterval(this.interval);
    }
  
}
</script>