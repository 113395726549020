<template>
<div class="container-fluid" style=" background-color:#000">
  <div class="row" v-if="this.$store.state.account" >
    
    <div class="row" style = "margin-top:30px">

    <div class="col-4" style="color:#bbb">
      <div ><h4><u>Les dernières ventes</u></h4></div>
      <div style="margin-bottom:20px;" v-for="(item,index) in last10sales" v-bind:key="item.user">
        <div >
          {{index +1}} - {{item.client}} <span class="badge bg-primary rounded-pill">{{ item.totalCurrency }}</span><br>
          <i>{{ formatDate(item.createdon) }} par {{(item.user.split(' '))[0]}} {{(item.user.split(' '))[1].substring(0,1)}} </i>
        </div>
      </div>  
    </div> 

      <div class="col-8" >
        <div class="row" >
        <div class="col-4"><FigureBoard title="Aujourd'hui" :numberSales="total.dayCommandCurrency"/></div> 
          <div class="col-4"><FigureBoard title="Cette semaine" :numberSales="total.weekCommandCurrency"/></div> 
        <div class="col-4" style="align=right"><FigureBoard title="Ce mois-ci" :numberSales="total.monthCommandCurrency"/></div> 
      </div>
       <table class="table ">
            <thead class="table-dark">
                <tr>
                    <td>Chargé d'affaire</td>
                    <td>Aujourd'hui</td>
                    <td>Semaine</td>
                    <td>Mois</td>
                    <td>Mois dernier</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in detailLine" v-bind:key="item.id" style="color:#cccccc;font-size:18px">
                    <td>{{ item.lastname + ' ' + item.firstname }}</td>
                    <td>{{ item.dayCommandCurrency }}</td>
                    <td>{{ item.weekCommandCurrency  }}</td>
                    <td>{{ item.monthCommandCurrency  }}</td>
                    <td style="color:#555">{{ item.lastMonthCommandCurrency  }}</td>
                </tr>
            </tbody>
            <!--
            <tfoot>
                <tr class="table-primary" >
                    <td>{{ total.name }}</td>
                    <td>{{ total.dayCommandCurrency }}</td>
                    <td>{{ total.weekCommandCurrency  }}</td>
                    <td>{{ total.monthCommandCurrency  }}</td>
                    <td  style="color:#aaaaaa">{{ total.lastMonthCommandCurrency  }}</td>
                </tr>
            </tfoot>
            -->
        </table>
    </div>
</div>
<div class="row text-center">
        <p><small> {{reportName + ' : ' + reportMonth}}</small></p>
        <p><small><i>{{reportDescription }}</i></small></p>
</div>
</div>

  <div class="row" v-if="!this.$store.state.account">
      <h2>Vous n'avez pas les droits pour accéder à ce rapport</h2>
  </div>
</div>
</template>

<script>

import axios from 'axios'
import FigureBoard from '../../components/FigureBoard.vue'

export default {
  name: 'Report2',
  components : {
    FigureBoard
  },
  props: {
    msg: String
  }, 
  data () {
    return {
    reportName:'',
    reportMonth: '',
    reportDescription: '',
    detailLine: [],
    total: {},
    last10sales: [],
    info: null,
    interval: null
    }
  },
  mounted () {
    this.loadData()
    this.interval = setInterval(() => { this.loadData()},30000)
  },
  methods: {
      formatDate: function (dteTo){
        let dte = new Date(dteTo)
        let hour = dte.toLocaleTimeString()
        
        //console.log(dte, dte.setHours(0,0,0,0))
        
        let today = new Date()
        const yesterday = new Date()
        yesterday.setDate(today.getDate() -1);
        

        let formatted_date = ''
        dte.setHours(0,0,0,0) === today.setHours(0,0,0,0) ? formatted_date='Aujourd\'hui': ''
        dte.setHours(0,0,0,0) === yesterday.setHours(0,0,0,0) ? formatted_date='Hier': ''
        if(formatted_date === ''){
        formatted_date = 'Le ' + dte.getDate() + "-" 
        formatted_date += (dte.getMonth() + 1) <10 ? '0' + (dte.getMonth() + 1) : (dte.getMonth() + 1)
        formatted_date +=  "-" + dte.getFullYear()
        }
        formatted_date += ' à ' + hour
        return formatted_date ; 
      },
      loadData: function () {
        
        axios.get(process.env.VUE_APP_ROOT_API + '/report/sales')
      .then(response => {
        
          if(response.data){
            this.reportName= response.data.reportSalesOfMonth.Name
            this.reportMonth= response.data.reportSalesOfMonth.reportMonth
            this.reportDescription = response.data.reportSalesOfMonth.description
            this.detailLine = response.data.reportSalesOfMonth.board.detail
            this.total =  response.data.reportSalesOfMonth.board.total
            this.last10sales = response.data.reportSalesOfMonth.last10Sales
            this.detailLine.sort((a,b) => {return a.monthCommand>b.monthCommand ? -1: 1})
          }
        }).catch((err) => {alert(err.message)})
      }
  },
  beforeUnmount: function(){
        clearInterval(this.interval);
    }
  
}
</script>