<template>
    <div class="container" style="margin-top:50px" v-if="this.$store.state.account">  
        <h1>Liste des equipes</h1>
        <p><a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewEquipe(null, 2)"><span class='glyphicon glyphicon-edit'></span> Créer une nouvelle équipe</a></p>
        <table class="table table-striped" style="font-size:10px">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Inclure Dans statistiques</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for= "(item, index) in equipes" v-bind:key="item.Id">
                    <td>{{ index +1 }}</td>
                    <td>{{ item.Nom }}</td>
                    <td>{{ item.InclureDansStatistiques }}</td>

                    <td>
                        <a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewEquipe(item.Id, 3)"><span class='glyphicon glyphicon-edit'></span> Edit</a> |
                        <a data-bs-toggle="modal" data-bs-target="#modal-user" href="#" @click.stop.prevent="viewEquipe(item.Id, 1)"><span class='glyphicon glyphicon-barcode'></span> Details</a> |
                        <a  href="#" @click.stop.prevent="deleteEquipe(item.Id)"><span class='glyphicon glyphicon-ban-circle'></span> Delete |</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- Modal pour le CRUD -->
        <div id="modal-user" class="modal" tabindex="-1">
            <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">{{titleModal}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="alert alert-primary" role="alert" v-if="alert.show">
                        {{alert.message}}
                        </div>
                   
                    <div class="mb-3" >
                        <label for="nom" class="form-label">Nom</label>
                        <input type="text" class="form-control" id="nom" placeholder="Nom de l'équipe" :disabled="isViewMode" v-model="equipe.Nom">
                    </div>
                    <div class="mb-3" >
                        <label for="prenom" class="form-label">Inclure dans statistiques</label>
                        <input class="form-check-input" type="checkbox" id="InclureDansStatistiques"  :disabled="isViewMode" v-model="equipe.stats">
                    </div>
                   
                </form>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                <button type="button" class="btn btn-primary" v-if="!isViewMode" @click="saveEquipe()">{{mode === 2? 'Create new équipe' : 'Save changes'}}</button>
                </div>
            </div>
            </div>
        </div>
        
    </div>

    <div class="row" v-if="!this.$store.state.account" style="margin-top:50px">
        <h2>Vous n'avez pas les droits pour accéder à ce rapport</h2>
    </div>
</template>

<script>
    const axios = require('axios')

    
export default {
    name: 'HeaderBar',
    components :{
    }, 
    data() {
        return{
            equipes: [],
            equipe: {},
            oldId : 0,
            mode: 0, //1: view, 2 create, 3 edit
            alert : {show:false, message:''}
        }
    },
    mounted (){
        this.loadListEquipes()
      
    },
    methods: {
        loadListEquipes: function() {
            axios.get(process.env.VUE_APP_ROOT_API + '/phone/equipes')
            .then(response => {
                if(response.data){
                    console.log(response.data)
                    this.equipes = response.data
                }
            })  
        },
        deleteEquipe :  function (id){
          
            if(confirm("Voulez-vous réellement supprimer cet enregistrement")){
                 axios.delete(process.env.VUE_APP_ROOT_API + '/phone/equipes/' + id)
                .then(() => {
                   
                    alert("Suppression réalisée avec succès")
                    this.loadListEquipes()
                     
                }).catch((err) => {
                    alert('erreur dans la suppresion : ' + err)
                })
            }
        },
        saveEquipe: function() {
            this.alert.show = false
            this.alert.message = ''

            if(this.mode !== 2 && this.mode !== 3) {
                this.alert.show = true
                this.alert.message = "Erreur inconnue, impossible d'enregitrer l'utilisateur "
                return
            }

            if(!this.equipe.Nom || this.equipe.Nom.trim() === "") {
                this.alert.show = true
                this.alert.message = "Le nom est obligatoire "
                return
            }

            this.equipe.InclureDansStatistiques = this.equipe.stats === true ? 1 : 0
            
            if(this.mode === 2){
                axios.post(process.env.VUE_APP_ROOT_API + '/phone/equipes/', this.equipe)
                .then((response) => {
                    this.equipe=response.data
                    this.mode = 1
                    this.loadListEquipes()
                }).catch((err) => {
                    this.alert.message = err
                    this.alert.show = true
                })
            }else if(this.mode === 3){

                axios.put(process.env.VUE_APP_ROOT_API + '/phone/equipes/' + this.oldId, this.equipe)
                .then((response) => {
                    this.equipe=response.data
                    this.mode = 1
                    this.loadListEquipes()
                     
                }).catch((err) => {
                    this.alert.message = err
                    this.alert.show = true
                })
            }
        },
        viewEquipe: function(id, mode) {
            this.oldId = id
            this.alert.show = false
            this.alert.message = ''
            this.equipe = {}
            this.mode = 0
            if(id) {
                axios.get(process.env.VUE_APP_ROOT_API + '/phone/equipes/' +id)
                .then(response => {
                    if(response.data){
                        this.mode = mode //3 edit, 2 view
                        this.equipe = response.data
                        this.equipe.stats = this.equipe.InclureDansStatistiques ===1?true:false
                    }
                })
            
            }else{
                this.mode = mode
            }
        }
     },
    computed: {
        isViewMode: function () {
            return this.mode === 1 || this.mode === 0 ? true: false
        },
        titleModal :  {
            get: function () {
                let title = ""
                if(this.mode === 1){
                    title= "Visualisation de l'équipe " + this.equipe.Poste + 
                    " (" + this.equipe.Nom + ")"
                }
                if(this.mode === 2){
                    title = "Création d'un nouvel équipe"
                }
                if(this.mode === 3){
                    title= "Modification de l'équipe " + this.equipe.Poste + 
                    " (" + this.equipe.Nom + ")"
                }
                return title           
            }
        }
    }
}

</script>