<template>
    <h4 style="color:#FFFFFF">{{title}}</h4>
    <h1 style="color:#FFFFFF">{{numberSales}}</h1>
</template>

<script>


export default {
    props : {
        title: String,
        numberSales: String

    }
}
</script>