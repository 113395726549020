import { createStore } from "vuex" 


const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_AD_CLIENT_ID ,
          authority: process.env.VUE_APP_AD_AUTHORITY,
          redirectUri: process.env.VUE_APP_AD_REDIRECT_URI
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      accessToken: '',
      account: undefined
    };
  },
  mutations :{
    setAccessToken(state, token){
      state.accessToken = token;
      
    },
    setAccount(state, account){
      
        state.account = account
        console.log('ACCOUNT CHANGED')
    }
  }
});

export default store;